import { API } from "./api.js";
export default {
  async createTrigger(data) {
    try {
      const response = await API.post("/api/rest/v2/mailtrigger", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haaltriggersOp() {
    try {
      const response = await API.get("/api/rest/v2/mailtrigger");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haalEenTriggerId(id) {
    try {
      const response = await API.get(`/api/rest/v2/mailtrigger/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateTrigger(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/mailtrigger/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteSTrigger(id) {
    try {
      const response = await API.delete(`/api/rest/v2/mailtrigger/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async mailJobs(data) {
    try {
      const response = await API.delete(`/api/rest/v2/mailjobs`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
