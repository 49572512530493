import { API } from "./api.js";

export default {
  async createMailTemplate(data) {
    try {
      const response = await API.post("/api/rest/v2/mailtemplate", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getMailTemplatas() {
    try {
      const response = await API.get("/api/rest/v2/mailtemplate");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteMailTemplate(id) {
    try {
      const response = await API.delete(`/api/rest/v2/mailtemplate/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateMailTemplate(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/mailtemplate/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getMailTemplateById(id) {
    try {
      const response = await API.get(`/api/rest/v2/mailtemplate/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
