<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="5">
        <v-card>
          <v-card-title>Mailtrigger Instellingen</v-card-title>
          <v-card-text>
            <v-form>
               <v-select
                v-model="trigger.templateName"
                :items="templates"
                label="Template naam"
                item-text="templateName"
                item-value="id"
                outlined
                dense
              ></v-select>

              <v-text-field
                v-model="trigger.aantalPersonen"
                label="Minimaal aantal personen"
                outlined
                dense
                type="number"
              ></v-text-field>

              <v-select
                v-model="trigger.condition"
                :items="dagenOpties"
                label="Minimaal aantal dagen"
                outlined
                dense
              
              ></v-select>

              <v-textarea
                v-model="trigger.message"
                label="Informatie trigger"
                outlined
                dense
              ></v-textarea>

              <v-switch
                v-model="trigger.enabled"
                label="Actief"
                outlined
                dense
              ></v-switch>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveTrigger">Opslaan</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="7">
        <v-card>
          <v-card-title>Gestelde Triggers</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="mailtriggers"
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteTrigger(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiMailTrigger from "../api/apiMailTrigger";
import apiMailTemplate from "../api/apiMailTemplate";
export default {
  data() {
    return {
      dagenOpties: [...Array(14).keys()].map((i) => i + 1),
      templates: [],
      mailtriggers: [],
      trigger: {
        templateName: "",
        condition: "",
        message: "",
        aantalPersonen:"",
        enabled: false,
      },
      triggers: [],
      headers: [
        { text: "#ID", value: "training_id" },
        { text: "Minimaal personen", value: "  min_registrations" },
        { text: "Aantaldagen", value: "days_before_expire" },
        { text: "Template", value: "templateName" },

        { text: "Bericht", value: "message" },
        { text: "Status", value: "enabled" },
        { text: "Actief", value: "enabled", sortable: false },

        { text: "Acties", value: "actions", sortable: false },
      ],
    };
  },
  async mounted() {
    apiMailTemplate.getMailTemplatas().then((data) => {
      this.templates = data;
    });
    await this.fetchTriggers();
  },
  methods: {
    resetForm() {
      this.trigger = {
        name: "",
        type: "",
        condition: "",
        message: "",
        enabled: false,
        templateName: "",
      };
    },
    fetchTriggers() {
      apiMailTrigger.haaltriggersOp().then((data) => {
        this.mailtriggers = data;
      });
    },
    async saveTrigger() {
      try {
        if (!this.trigger.name || !this.trigger.templateName) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }
        const response = await apiMailTrigger.createTrigger(this.trigger);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        this.fetchTriggers();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteTrigger(item) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze mailtrigger wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiMailTrigger.deleteSTrigger(item.id);
        this.fetchTriggers();
        this.$swal.fire(
          "Verwijderd",
          "De Mailtrigger is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
